/* Fix Button question theme */
.ls-answers .btn-primary.answer-item, .ls-answers .button-item + .btn-primary{
    @include button-variant($body-bg, $primary);
}
.ls-answers .btn-check:checked + .btn-primary.answer-item, .ls-answers .button-item.btn-check:checked + .btn-primary {
    @include button-variant($primary, $primary);
}
.ls-answers .btn-primary.answer-item:hover, .ls-answers .button-item + .btn-primary:hover,
.ls-answers .btn-primary.answer-item:focus, .ls-answers .button-item + .btn-primary:focus{
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}
 
/* img select */
.imageselect-list li.imageselect-listitem .imageselect-container .imageselect-label{
    border: ($spacer * .5) solid $body-secondary-bg;
}
.imageselect-list li.imageselect-listitem .imageselect-container input.imageselect-checkbox:checked + label {
    border: ($spacer * .5) solid $primary;
}

/* Fix for boostrap .btn-outline-secondary on dark mode @todo : find a better way */
@media (prefers-color-scheme: dark) {
    .btn-outline-secondary{
        @include button-outline-variant($gray-400);
    }
}
