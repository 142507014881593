
//
// Checkboxes
// --------------------------------------------------


$font-family-icon: 'FontAwesome' !default;
$fa-var-check: "\f00c" !default;
$check-icon: $fa-var-check !default;
$abc-border-width: 1px !default;
$form-check-input-gutter: 1.25rem !default;

@function theme-color($key: "primary") {
  @return map-get($theme-colors, $key);
}


.awesome-checkbox .checkbox-item{
  padding-left: $form-check-input-gutter;

  label{
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    padding-left: 5px;

    &::before{
      cursor: pointer;
      content: "";
      display: inline-block;
      position: absolute;
      width: 17px;
      height: 17px;
      top: 2px;
      left: 0;
      margin-left: -$form-check-input-gutter;
      border: $abc-border-width solid $input-border-color;
      border-radius: 3px;
      background-color: $input-bg;
      @include transition(border 0.15s ease-in-out, color 0.15s ease-in-out);
    }

    &::after{
      cursor: pointer;
      display: inline-block;
      position: absolute;
      width: 16px;
      height: 16px;
      left: 0;
      top: 2px;
      margin-left: -$form-check-input-gutter;
      padding-left: 3px;
      padding-top: 1px;
      font-size: 11px;
      color: $primary;
      background-color: $input-bg;
    }
  }

  input[type="checkbox"],
  input[type="radio"] {
    position: static;
    margin-left: 0;
    cursor: pointer;
    opacity: 0;
    z-index: 1;

    &:focus + label::before{
      outline: thin dotted;
      outline: 5px auto -webkit-focus-ring-color;
      outline-offset: -2px;
    }
    &:focus-visible + label::before{
        outline: 0;
        border-color: $input-focus-border-color;
        @if $enable-shadows {
          @include box-shadow($input-box-shadow, $input-focus-box-shadow);
        } @else {
          box-shadow: $input-focus-box-shadow;
        }
    }
    &:checked + label::after{
      font-family: $font-family-icon;
      content: $check-icon;
    }

    &:indeterminate + label::after{
      display: block;
      content: "";
      width: 10px;
      height: 3px;
      background-color: $input-bg;
      border-radius: 2px;
      margin-left: -16.5px;
      margin-top: 7px;
    }

    &:disabled + label{
      opacity: 0.65;

      &::before{
        background-color: $input-disabled-bg;
        cursor: not-allowed;
      }

      &::after{
        cursor: not-allowed;
      }
    }

  }

  &.abc-checkbox-circle label::before{
    border-radius: 50%;
  }

  &.checkbox-inline{
    margin-top: 0;
  }
}

//
// Radios
// --------------------------------------------------

@mixin radio-variant($parent, $color) {
  #{$parent} input[type="radio"]{
    + label{
      &::after{
        background-color: $color;
      }
    }
    &:checked + label{
      &::before {
        border-color: $color;
      }
      &::after{
        background-color: $color;
      }
    }
  }
}

.awesome-checkbox .radio-item{
  padding-left: $form-check-input-gutter;

  label{
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    padding-left: 5px;

    &::before{
      cursor: pointer;
      content: "";
      display: inline-block;
      position: absolute;
      width: 17px;
      height: 17px;
      top: 2px;
      left: 0;
      margin-left: -20px;
      border: $abc-border-width solid $input-border-color;
      border-radius: 50%;
      background-color: $input-bg;
      @include transition(border 0.15s ease-in-out);
    }

    &::after{
      cursor: pointer;
      display: inline-block;
      position: absolute;
      content: " ";
      width: 11px;
      height: 11px;
      left: 3px;
      top: 5px;
      margin-left: -20px;
      border-radius: 50%;
      background-color: $primary;
      transform: scale(0, 0);

      transition: transform .1s cubic-bezier(.8,-0.33,.2,1.33);
      //curve - http://cubic-bezier.com/#.8,-0.33,.2,1.33
    }
  }

  input[type="radio"]{
    position: static;
    margin-left: 0;
    cursor: pointer;
    opacity: 0;
    z-index: 1;

    &:focus + label::before{
      outline: thin dotted;
      outline: 5px auto -webkit-focus-ring-color;
      outline-offset: -2px;
    }
    &:focus-visible + label::before{
        outline: 0;
        border-color: $input-focus-border-color;
        @if $enable-shadows {
          @include box-shadow($input-box-shadow, $input-focus-box-shadow);
        } @else {
          box-shadow: $input-focus-box-shadow;
        }
    }

    &:checked + label::after{
      transform: scale(1, 1);
    }

    &:disabled + label{
      opacity: 0.65;

      &::before{
        cursor: not-allowed;
      }

      &::after{
        cursor: not-allowed;
      }
    }

  }

  &.radio-inline{
    margin-top: 0;
  }
}

// Validation states
// TODO: keep in sync with bootstrap since strange comment 'redo check markup lol crap'
.awesome-checkbox .form-check-input {
  .was-validated &:invalid,
  &.is-invalid {
    .checkbox-item,
    .radio-item {
      label &:before {
        border-color: $form-feedback-invalid-color;
      }
    }
  }
}
