$variable-prefix:             bs-;
$prefix:                      $variable-prefix;

$primary: #2D4576;
$secondary: #495057;
$success: #31572C;
$info:  #38496c;
$warning: #684f03;
$danger: #952D3E;

$color-mode-type: media-query;

$body-color: #2F2D2E;
$body-bg: #FAF8F8;
$body-secondary-bg: #f2eded;
$body-emphasis-color: #000000;

$link-color: #1A4666;;
$link-hover-color: #22355A;

$input-bg: var(--#{$prefix}body-bg);
